

  <template>
    <empleados-card/>
  </template>

<script>

import {BRow, BCol} from 'bootstrap-vue'
import EmpleadosCard from '@/modules/ejecutante/views/catalogos/empleados/EmpleadosCard.vue'
export default {
  name: 'EmpleadosView',
  components: {
    BRow,
    BCol,
    EmpleadosCard
  }

}
</script>

<style scoped>

</style>
