`<template>

  <div>
    <!--- loading state -->
    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>
    <!-- data section -->
    <template v-else>


      <!-- error section -->
      <template v-if="userData.length == 0">
        <b-alert
            variant="danger"
            show
        >
          <h4 class="alert-heading">Error al obtener los datos del usuario</h4>
          <div class="alert-body">
            <h6>
              El usuario correspondiente a este ID no ha sido encontrado. Por favor, revise la lista de usuarios para ver otras opciones.
            </h6>
            <b-button
                variant="primary"
                @click="handleBack"
            >
              Volver a la lista
            </b-button>
          </div>



        </b-alert>
      </template>

      <!-- data  -->
      <template v-else>

        <!-- modo edicion -->
        <template v-if="isEditMode">

          <empleados-edit
              :empleado-prop="employeeDataProp"
              @closeEditMode="handleEdit(false)"
              @successEditMode="handleSuccessEdit( false )"
          />

        </template>

        <!-- modo lectura -->
        <template v-else>

          <!-- info user -->
          <b-card>

            <!-- back -->
            <b-row>
              <b-col>
                <b-button
                    variant="link"
                    class="p-0"
                    @click="handleBack"
                >
                  <feather-icon
                      icon="ArrowLeftIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Regresar</span>
                </b-button>
              </b-col>
            </b-row>


            <b-row class="mt-2">
              <b-col>
                <h3>{{ userData.empNombres }} {{ userData.empApellidos }}</h3>
                <span>{{ userData.empEmail }}</span>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col cols="12">
                <table>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Posición</span>
                    </th>
                    <td>
                      <div class="text-nowrap ml-10">
                        <feather-icon
                            icon="CircleIcon"
                            size="18"
                            class="mr-50"
                            :class="`text-${getTypeVariant(userData.posNombre)}`"
                        />
                        <span class="align-text-top text-capitalize">{{  userData.posNombre }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Estado</span>
                    </th>
                    <td>
                      <div class="text-nowrap">
                        <feather-icon
                            icon="CheckIcon"
                            size="18"
                            class="mr-50"
                            :class="`text-${getStatusVariant(userData.empEstado)}`"
                        />
                        <span class="align-text-top text-capitalize">{{ getStatusName( userData.empEstado )  }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Identificacion</span>
                    </th>
                    <td>
                      <div class="text-nowrap">
                        <feather-icon
                            icon="UserIcon"
                            size="18"
                            class="mr-50"
                        />
                        <span class="align-text-top text-capitalize">{{ userData.empIdentificacion }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Oficina</span>
                    </th>
                    <td>
                      <div class="text-nowrap">
                        <feather-icon
                            icon="HomeIcon"
                            size="18"
                            class="mr-50"

                        />
                        <span class="align-text-top text-capitalize">{{ userData.offNombre }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Departamento</span>
                    </th>
                    <td>
                      <div class="text-nowrap">
                        <feather-icon
                            icon="BoxIcon"
                            size="18"
                            class="mr-50"

                        />
                        <span class="align-text-top text-capitalize">{{ userData.secNombre }}</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Inicio</span>
                    </th>
                    <td>
                      <div class="text-nowrap">
                        <feather-icon
                            icon="CalendarIcon"
                            size="18"
                            class="mr-50"

                        />
                        <span class="align-text-top text-capitalize">{{ userData.empInicia }}</span>
                      </div>
                    </td>
                  </tr>

                </table>
              </b-col>

            </b-row>

            <b-row class="mt-2">
              <b-col>
                <b-button
                    variant="primary"
                    @click="handleEdit( true )"
                >
                  Editar
                </b-button>
              </b-col>
            </b-row>

          </b-card>

          <!-- danger zone -->
          <b-card title="Zona de riesgo">
            ¡Atención! Está a punto de eliminar un colaborador de forma permanente. Esta acción no puede deshacerse. Por favor, asegúrese de que desea continuar antes de hacer clic en el botón "Eliminar".
            <b-row class="mt-2">
              <b-col>
                <b-button
                    variant="danger"
                    @click="handleDelete"
                    :disabled="isBusy"
                >
                    <template v-if="isBusy">
                        <b-spinner small />
                        Eliminando...
                    </template>
                    <template v-else>
                        Eliminar Colaborador
                    </template>
                </b-button>

              </b-col>
            </b-row>
          </b-card>

        </template>


      </template>



    </template>


  </div>

</template>


<script>
import {
  BAlert,
  BLink,
  BSpinner,
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol
} from 'bootstrap-vue'

import {
  resolveStatusVariant,
  resolveStatusName,
  resolveTypeName,
  resolveTypeIcon,
  resolveTypeVariant
} from '@/modules/common/utils'

import { mapActions } from 'vuex'
import {deleteEmpleados} from "@/modules/ejecutante/store/empleados/actions";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'EmpleadosCard',
  components: {
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    EmpleadosEdit: () => import('@/modules/ejecutante/views/catalogos/empleados/EmpleadosEdit.vue')
  },
  data(){
    return {

      isLoading: false,
      isEditMode: false,
        isBusy: false,
      userData: []

    }
  },
  computed: {

    queryUuid() {

      const { uuid } = this.$route.query

      return uuid ?? null

    },

    employeeDataProp() {
      return { ...this.userData }
    }
  },
  methods:{

    ...mapActions('empleado-module', ['findOne','deleteEmpleados']),

    // buscar informacion del colaborador
    async loadData(){

      try {

        this.userData = []

        this.isLoading = true

        const  data  = await this.findOne( { 'uuid': this.queryUuid } )

        this.userData = data

        this.isLoading = false

      } catch ( error ) {




      } finally {
        this.isLoading = false
      }

    },

    // redireccionar a la lista
    handleBack(){
      this.$router.push({name: 'catalogo-colaborador'})
    },

    // procesar accion edit
    handleEdit( editMode ){
      this.isEditMode = editMode
    },

    // procesar accion edit exitosa
    async handleSuccessEdit( editMode ){
      await this.loadData()
      this.isEditMode = editMode
    },


      // procesar accion delete
      async handleDelete(){

          const isConfirm = await  this.$bvModal.msgBoxConfirm(
              '¿Estás seguro que deseas eliminar este colaborador? Esta acción es irreversible y eliminará toda su información.',
              {
                  title: 'Eliminar colaborador',
                  okVariant: 'danger',
                  okTitle: 'Sí, eliminar colaborador',
                  cancelTitle: 'No',
                  cancelVariant: 'secondary',
                  modalClass: "modal-danger",
                  hideHeaderClose: false,
                  centered: true,
              })

          if ( ! isConfirm )  return

          try {

              this.isBusy = true

              this.deleteEmpleados( this.userData.empUuid )

              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Operación exitosa',
                      icon: 'CheckIcon',
                      text: 'La eliminación del colaborador se ha realizado correctamente.',
                      variant: 'success'
                  },
              })

              this.isBusy = false

              this.$router.push({name: 'catalogo-colaborador'})

          } catch ( error ) {

              console.log(error)

          }


      },


    getStatusVariant( status ){
      return resolveStatusVariant( status )
    },

    getStatusName( status ) {
      return resolveStatusName( status )
    },

    getTypeName( type ) {
      return resolveTypeName( type )
    },

    getTypeVariant( type ){
      return resolveTypeVariant( type )
    },
    getTypeIcon( type ){
      return resolveTypeIcon( type )
    },


  },
  async created() {
    await this.loadData()
  }

}
</script>

<style scoped>

</style>
